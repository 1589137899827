import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import DetailsTable from 'components/details-table';
import SingleTable from 'components/details-table/single-table';
import GroupController from 'components/expansion-panel/group-control-button';
import Filter from 'components/filter';
import FilterSelect from 'components/filter-select';
import Loader from 'components/loader';

import { utilizationsByMonthAccess } from 'core/auth/guaranteedAccessRoles';
import { commonActions } from 'core/common/actions';
import { UNITS_TYPES } from 'core/constants';

import { deliveryCenterUtilizationByMonthActions } from 'core/delivery-center-utilization-by-month/actions';
import { STORE_KEYS } from 'core/delivery-center-utilization-by-month/constants';
import reducer from 'core/delivery-center-utilization-by-month/reducer';
import saga from 'core/delivery-center-utilization-by-month/sagas';
import {
  selectDevCenters,
  selectSelectedReportDate,
  selectSelectedDateFormatted,
  selectSelectedFullDateFormatted,
  selectDevCenterFilters,
  selectDetailReport,
  selectDetailReportFilteredFormatted,
  selectDetailReportSummaryFormatted,
  selectOrderRules,
  selectEntityName,
  selectIsFetching,
  selectHasAccessToRevenue,
  selectProjectTypesList,
  selectBillTypesList,
  selectUtilizationByMonthReportFilters,
  selectFiltersSelected,
  selectActiveFilters,
} from 'core/delivery-center-utilization-by-month/selectors';
import GoTop from 'elements/go-top-button';
import StyledWrapper from 'elements/styled-wrapper';
import Wrapper from 'elements/wrapper';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { updateState } from 'utils/helpers/history';
import { injectReducer, injectSaga } from 'utils/helpers/injectors';
import { autoScrollToTop } from 'utils/react';

import resourceRowsFilter from './filter-columns-config';

import {
  filtersWrapperCssRules,
  controlsCssRules,
  calendarCssRules,
  controlsWrapperCssRules,
  calendarItemCssRules,
  filterSelectCssRules,
  wrapperCssRules,
  tableCssRules,
  filterColumnCssRules,
  summaryRowsCssRules,
} from './styles';
import totalsDataTemplate from './summary-columns-config';

const model = {
  dataTemplate: totalsDataTemplate,
  rules: {
    css: summaryRowsCssRules,
  },
};

const calendarStepControlsConfig = {
  step: 1,
  unitName: 'month',
};
const calendarPopperProps = {
  placement: 'bottom-start',
};

const resourceRowsControls = [];
const summaryRowsControls = [];

const devCentersGetOptionValue = (option) => option.value;
const devCentersGetOptionLabel = (option) => option.label;

const excludedDevCenters = [3, 4];

const DeliveryCenterUtilizationByMonth = (props) => {
  const {
    location,
    changeFilter,
    changeMainFilter,
    devCenters,
    selectedReportDate,
    displayedDate,
    displayedFullDate,
    devCenterFilters,
    getUtilizationReport,
    resourceRows,
    detailReport,
    projectTypes,
    billTypes,
    summaryRows,
    orderRules,
    changeOrder,
    entityName,
    isFetching,
    resetFilters,
    isFiltersActive,
    filtersSelected,
  } = props;

  const byIdFull = Object.keys(devCenters.byIdFull).reduce((acc, key) => {
    if (!excludedDevCenters.includes(parseInt(key))) {
      acc[key] = devCenters.byIdFull[key];
    }
    return acc;
  }, {});

  const forSelectFull = devCenters.forSelectFull.filter((item) => !excludedDevCenters.includes(item.value));

  autoScrollToTop(location);
  const [expandSettings, setExpandSettings] = useState({ date: null, defaultExpand: true });
  const [groupControllerConfig, setGroupControllerConfig] = useState({ changed: false, expand: true });

  const resetFilter = () => {
    resetFilters();
  };

  const setDate = useCallback((date) => {
    changeMainFilter({
      [STORE_KEYS.MONTH]: date,
    });
    resetFilters();
    getUtilizationReport();
    setExpandSettings({ date, defaultExpand: true });
    setGroupControllerConfig({ changed: false, expand: true });
  }, []);

  useEffect(() => {
    const activevcenters = Object.keys(byIdFull).length;
    if (activevcenters) {
      changeMainFilter({
        [STORE_KEYS.DEVCENTER]: Object.keys(byIdFull),
      });
      getUtilizationReport();
    }
  }, [devCenters]);

  const onSelectDevCenter = useCallback((values) => {
    const ids = values.length ? values.map((i) => i.value) : Object.keys(byIdFull);
    updateState({}, `/${utilizationsByMonthAccess.route}/${ids}`);
    changeMainFilter({
      [STORE_KEYS.DEVCENTER]: ids,
    });
    getUtilizationReport();
  }, [devCenters]);

  const taskOrders = resourceRows.reduce((sum, item) => {
    item.details.forEach((i) => {
      if (i.billType === 'INT' && !sum.includes('INT')) {
        return sum.push('INT');
      } if (i.taskOrder === null && !sum.includes('NYA')) {
        return sum.push('NYA');
      }
      return sum.includes(`${i.taskOrder}`) || !i.taskOrder ? sum : sum.push(`${i.taskOrder}`);
    });
    return sum;
  }, []);

  const resourceRowsFilters = useMemo(() => {
    const itemsMap = ({
      resource: resourceRows,
      taskOrder: taskOrders,
      projectType: projectTypes,
      billType: billTypes,
    });

    return resourceRowsFilter.reduce((acc, row) => [
      ...acc,
      {
        ...row,
        filterConfig: {
          items: itemsMap[row.storeKey],
          storeKey: row.storeKey,
          selected: filtersSelected[row.storeKey],
        },
        changeFilter: ({ selected, storeKey, type }) => {
          switch (storeKey) {
            case 'resource': {
              changeFilter({
                storeKey,
                [storeKey]: selected.map((item) => item[storeKey]),
                selected: selected.map((item) => item[storeKey]),
                type,
              });
              return;
            }

            default: {
              changeFilter({
                [storeKey]: selected,
                storeKey,
                selected,
                type,
              });
            }
          }
        },
      },
    ], []);
  }, [resourceRowsFilter, changeFilter, changeMainFilter, resourceRows, taskOrders, projectTypes, billTypes, filtersSelected]);

  const getGroupControllerConfig = (a) => {
    setGroupControllerConfig({ changed: true, expand: a });
  };

  return (
    <>
      <Controls
        controlsHeading="Utilization Report by Resource"
        cssRules={controlsCssRules}
        wrapperCssRules={controlsWrapperCssRules}
      >
        <GroupController
          groupId="resource"
          expandSettings={expandSettings}
          getGroupControllerConfig={getGroupControllerConfig}
        />
        <StyledWrapper cssRules={filtersWrapperCssRules}>
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="year"
            currentDate={moment()}
            onChange={setDate}
            withYearSelecting
            stepControlsConfig={calendarStepControlsConfig}
            cssRules={calendarCssRules}
            value={selectedReportDate}
            popperProps={calendarPopperProps}
          >
            <StyledWrapper cssRules={calendarItemCssRules}>
              {displayedDate}
            </StyledWrapper>
          </Calendar>
          <FilterSelect
            filters={devCenterFilters}
            placeholderLength="16.5rem"
            placeholder="All Delivery Centers"
            onChange={onSelectDevCenter}
            items={forSelectFull}
            cssRules={filterSelectCssRules}
            getOptionValue={devCentersGetOptionValue}
            getOptionLabel={devCentersGetOptionLabel}
            isMultiple
            withChips={false}
          />
        </StyledWrapper>
      </Controls>
      <Loader isLoaded={!isFetching}>
        <Wrapper cssRules={wrapperCssRules}>
          <SingleTable
            title="Utilization Summary for selected period"
            controls={summaryRowsControls}
            entityName={entityName}
            type={UNITS_TYPES.FILTER}
            idKey="resource"
            dataKey="summaryRows"
            tableName="detailReportSummary"
            data={summaryRows}
            model={model}
            orderRules={orderRules}
            changeOrder={changeOrder}
            isFetchingDetails={isFetching} /* isFetchingDetails */
          />
          <div className="details-table__title-wrapper">
            <p className="details-table__title">
              Utilization Detail for selected period
            </p>
          </div>
          <div className="details-table__subtitle">
            <p>Note: data current as of {displayedFullDate}</p>
          </div>
          <Filter
            cssRules={filterColumnCssRules}
            filterConfig={resourceRowsFilters}
            changeFilter={changeFilter}
            hasActiveFilters={isFiltersActive}
            resetFilters={resetFilter}
            isSticky
          />

          {detailReport.length ? (
            <DetailsTable
              entityName={entityName}
              type={UNITS_TYPES.FILTER}
              idKey="resource"
              dataKey="resourceRows"
              tableName="detailReport"
              controls={resourceRowsControls}
              content={detailReport}
              tableCssRules={tableCssRules(groupControllerConfig.expand)}
              orderRules={orderRules}
              changeOrder={changeOrder}
              isFetchingDetails={isFetching} /* isFetchingDetails */
              preventScrolling
              withInfiniteScroll
              groupControllerConfig={groupControllerConfig}
            />
          ) : (
            <div className="table__warning">
              <p className="table__warning-title">Sorry, no content matched your criteria. </p>
              <p className="table__warning-description">Please change the selected filters.</p>
            </div>
          )}
        </Wrapper>
      </Loader>
      <GoTop />
    </>
  );
};

DeliveryCenterUtilizationByMonth.propTypes = {
  location: PropTypes.object.isRequired,
  deliveryCenterIds: PropTypes.arrayOf(PropTypes.number),
  devCenters: PropTypes.shape({}).isRequired,
  selectedReportDate: PropTypes.shape({}),
  displayedDate: PropTypes.string,
  displayedFullDate: PropTypes.string,
  devCenterFilters: PropTypes.arrayOf(PropTypes.shape({})),
  detailReport: PropTypes.arrayOf(PropTypes.shape({
    summaryConfig: PropTypes.shape({
      groupId: PropTypes.string,
      isDefaultExpanded: PropTypes.bool,
      label: PropTypes.string,
    }),
    summaryContent: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      tableData: PropTypes.arrayOf(PropTypes.shape({})),
      stylesTemplate: PropTypes.shape({}),
      isFilter: PropTypes.bool,
      hasWarning: PropTypes.bool,
      isActive: PropTypes.bool,
    })),
    model: PropTypes.shape({
      dataTemplate: PropTypes.arrayOf(PropTypes.shape({})),
      rules: PropTypes.shape({
        css: PropTypes.shape({}),
      }),
    }),
  })),
  resourceRows: PropTypes.arrayOf(PropTypes.shape({
    resource: PropTypes.string,
    fullname: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape({
      logDate: PropTypes.string,
      projectName: PropTypes.string,
      order: PropTypes.number,
      billType: PropTypes.string,
      isBillable: PropTypes.bool,
      logHours: PropTypes.number,
    })),
    total: PropTypes.number,
  })),
  projectTypes: PropTypes.arrayOf(PropTypes.string),
  billTypes: PropTypes.arrayOf(PropTypes.string),
  summaryRows: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    hours: PropTypes.number,
    percentOfLogged: PropTypes.number,
    percentOfMonth: PropTypes.number,
  })),
  orderRules: PropTypes.shape({}),
  changeOrder: PropTypes.func.isRequired,
  entryName: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  hasAccessToRevenue: PropTypes.bool.isRequired,
};

DeliveryCenterUtilizationByMonth.defaultProps = {
  displayedDate: null,
  displayedFullDate: null,
  selectedReportDate: null,
  deliveryCenterIds: null,
  entryName: null,
  resourceRows: null,
  projectTypes: null,
  billTypes: null,
};

const mapStateToProps = createStructuredSelector({
  devCenters: selectDevCenters,
  selectedReportDate: selectSelectedReportDate,
  displayedDate: selectSelectedDateFormatted,
  displayedFullDate: selectSelectedFullDateFormatted,
  devCenterFilters: selectDevCenterFilters,
  resourceRows: selectDetailReport,
  detailReport: selectDetailReportFilteredFormatted,
  projectTypes: selectProjectTypesList,
  billTypes: selectBillTypesList,
  summaryRows: selectDetailReportSummaryFormatted,
  orderRules: selectOrderRules,
  entityName: selectEntityName,
  isFetching: selectIsFetching,
  hasAccessToRevenue: selectHasAccessToRevenue,
  filters: selectUtilizationByMonthReportFilters,
  isFiltersActive: selectActiveFilters,
  filtersSelected: selectFiltersSelected,
});

const mapDispatchToProps = {
  changeFilter: deliveryCenterUtilizationByMonthActions.changeFilter,
  changeMainFilter: deliveryCenterUtilizationByMonthActions.changeMainFilter,
  resetFilters: deliveryCenterUtilizationByMonthActions.resetFilters,
  changeOrder: commonActions.changeOrder,
  getUtilizationReport: deliveryCenterUtilizationByMonthActions.getUtilizationReport,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'deliveryCenterUtilizationByMonth',
  reducer,
});
const withSaga = injectSaga({
  key: 'deliveryCenterUtilizationByMonthSaga',
  saga,
});

export default compose(
  withRouter,
  withReducer,
  withSaga,
  withConnect
)(DeliveryCenterUtilizationByMonth);
